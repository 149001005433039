@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Helvetica Neue", Arial, sans-serif;
}

body {
  @apply bg-gray-800;
}

h3 {
  @apply text-center text-4xl font-semibold my-4;
}

h4 {
  @apply text-2xl font-semibold;
}

hr {
  margin-block: 16px;
}

img.picture {
  border-radius: 50%;
  display: block;
  margin-inline: auto;
  content: url("./assets/images/profile_pic.jpg");
  transform: scale(100%);
  transition: all 0.5s ease;
}

img.picture:hover {
  content: url("./assets/images/profile_pic_smiley.jpg");
  transform: scale(105%);
  transition: all 0.1s ease;
}
